import React from 'react';
import PropTypes from 'prop-types';
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import Slider from "../../components/Slider/Slider";
import Toggle from "../../components/Toggle/Toggle";
import SimpleSlide from "../../components/Slider/Slides/SimpleSlide/SimpleSlide";

import "./Features.scss";

const Features = ({ title, slides, categories, smallSlides, dir, className}) => {

    const [active, setActive] = React.useState(categories ? categories[0].value : "safety");

    const options = categories || [
        {
            "label": "features.safety_title",
            "value": "safety"
        },
        {
            "label": "features.performance_title",
            "value": "performance"
        }
    ];

    const onToggle = value => setActive(value);
    const renderSlide = slideInfo => <SimpleSlide className={smallSlides[active].length < 3 ? "half" : ""} key={slideInfo.title} slideInfo={slideInfo} />;

    return (
        <section className={`pp-section pp-section-features ${className || ""}`}>
            <div className={"container"}>
                <TitleBlock title={title}
                            className={"center"} />
            </div>
            <div className={"container slider-container"}>
                <Toggle options={options}
                        callback={onToggle} />
            </div>
            <div className={"container"}>
                <Slider slides={slides[active]}
                        className={"visible"}
                        dir={dir}
                        config={{ slidesPerView: 1, spaceBetween: 40 }} />
                <Slider slides={smallSlides[active]}
                        config={{ slidesPerView: smallSlides[active].length < 3 ? 2 : 3, spaceBetween: 24 }}
                        useControls={false}
                        usePagination={true}
                        className={"small"}
                        dir={dir}
                        renderSlide={renderSlide} />
            </div>
        </section>
    );

};

Features.propTypes = {
    title: PropTypes.string,
    slides: PropTypes.object,
    smallSlides: PropTypes.arrayOf(PropTypes.object),
};

export default Features;
