import React from 'react';
import PropTypes from 'prop-types';
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import Slider from "../../components/Slider/Slider";
import Toggle from "../../components/Toggle/Toggle";
import SimpleSlide from "../../components/Slider/Slides/SimpleSlide/SimpleSlide";

// import "./Features.scss";

const SmallSlider = ({title, slides, dir}) => {

    const renderSlide = slideInfo => <SimpleSlide key={slideInfo.title} slideInfo={slideInfo}/>;

    return (
        <section className={`pp-section pp-section-features no-top`}>
            <div className={"container"}>
                <TitleBlock title={title}
                            className={"center"}/>
            </div>
            <div className={"container"}>
                <Slider slides={slides}
                                       config={{slidesPerView: 4, spaceBetween: 24}}
                                       useControls={!slides}
                                       usePagination={true}
                                       className={"small"}
                                       dir={dir}
                                       renderSlide={renderSlide}/>
            </div>
        </section>
    );

};

SmallSlider.propTypes = {
    title: PropTypes.string,
    slides: PropTypes.object
};

export default SmallSlider;
