import React from 'react';
import SvgSprite from 'react-svg-sprite'

const Sprite = () => {
	return (
		<SvgSprite
			style={{
				position: "absolute",
				top: "-99999px",
				left: "-99999px",
				zIndex: "19999999",
				display: "block"
			}}
			symbols={[
				{
					name: "arrow",
					svg: `
					<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 15L8 8L1 0.999999" fill="none" />
					</svg>`
				},
				{
					name: "arrow-reverse",
					svg: `
					<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8 15L0.999999 8L8 0.999999" fill="none"/>
					</svg>
					`
				},
        {
          name: "arrow-color-reverse",
          svg: `
						<svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M11 1L1 11L11 21" fill="none" />
						</svg>
					`
        },
        {
          name: "arrow-color",
          svg: `
						<svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L11 11L1 21" fill="none" />
						</svg>
					`
        },
        {
          name: "play",
          svg: `
						<svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.41431 1.69437L14.0625 10.8643L1.41431 20.0342L1.41431 1.69437Z" stroke="white"/>
						</svg>
					`
        },
        {
          name: "close",
          svg: `
					<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
					    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
					        <g id="Artboard-Copy-27" transform="translate(-362.000000, -777.000000)" stroke="#FFFFFF" stroke-width="2">
					            <g id="close" transform="translate(363.000000, 778.000000)">
					                <path d="M0,0 L18,18" id="Path-6"></path>
					                <path d="M0,18 L18,0" id="Path-7"></path>
					            </g>
					        </g>
					    </g>
					</svg>
					`
        }
			]}
		/>
	)
};

export default Sprite;
