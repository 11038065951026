import React from 'react';
import PropTypes from 'prop-types';
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import Slider from "../../components/Slider/Slider";

import "./Interior.scss";

const Interior = ({ title, subtitle, slides, dir, className }) => {

  return (
    <section className={`pp-section pp-section-interior ${className || ""}`}>
      <div className={"container"}>
        <TitleBlock title={title}
                    subtitle={subtitle} />
        <Slider slides={slides}
                className={"visible"}
                dir={dir}
                config={{ slidesPerView: 2, spaceBetween: 40 }} />
      </div>
    </section>
  );

};

Interior.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Interior;
