import React from "react";
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import Configurator from "../../components/360vr/Configurator";
import "./configuratorSection.scss";
import PanoramaConfigurator from "../../components/360vr/PanoramaConfigurator";

const ConfiguratorSection = ({title, subtitle, className, data}) => {

    const [activeSection, setActiveSection] = React.useState("exterior");

    const toggleOptions = [
        {
            "label": "configurator.exterior",
            "value": "exterior"
        },
        {
            "label": "configurator.interior",
            "value": "interior"
        }
    ]

    const onToggle = (value) => {
        setActiveSection(value);
    }

    const sections = {
        "exterior": <Configurator data={data} options={toggleOptions} onToggle={onToggle.bind(this)} />,
        "interior": <PanoramaConfigurator data={data} options={toggleOptions} onToggle={onToggle.bind(this)}/>
    }


    return (
        <section className={`pp-section pp-section-configurator ${className || ""}`}>
            <div className={"container"}>
                <TitleBlock title={title} subtitle={subtitle} />
                {sections[activeSection]}
            </div>
        </section>
    )

};

export default ConfiguratorSection;
