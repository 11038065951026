import React from 'react';
import './SafeCare.scss';
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import Slider from "../../components/Slider/Slider";
import {LanguageContext} from "../../contexts/LanguageContext";
import PopupYoutube from "../../components/Popups/PopupYoutube";
import Image from "../../components/UI/Images/Image";
import Svg from "../../components/UI/SvgSprite/Svg";
import Button from "../../components/UI/Button/Button";

export const SafeCare = ({title, subtitle, data, dir}) => {
  const [slide, setSlide] = React.useState(1);

  /*const data = [
    {
      "video": "/assets/video/abaya.mp4",
      "icon": "/assets/images/araya.svg",
      "title": "Abaya Sensor",
      "text": "Tailored to Saudi female drivers, the Abaya Sensor alerts you with a sound if your abaya is stuck in the door before you drive. Because the best family car is the one that knows how to keep you safe."
    },
    {
      "video": "/assets/video/line.mp4",
      "icon": "/assets/images/lane.svg",
      "title": "Lane Keeping Assist",
      "text": "The Lane Keeping Assist alerts you if you unintentionally stray from the lane and steers you back. Because the best family car is the one that knows how to keep you safe."
    },
    {
      "video": "/assets/video/Forward_Collision.mp4",
      "icon": "/assets/images/collision.svg",
      "title": "Forward Collision Avoidance & Smart Cruise Control",
      "text": "The Forward Collision Avoidance Assist brakes for you when there is sudden braking or pedestrians in front of you. Activate the Smart Cruise Control to maintain a fixed speed while it adjusts your car to other vehicles around you. Because the best family car is the one that knows how to keep you safe."
    }
  ]*/

  const [dynamicTitle, setTitle] = React.useState(data[0].title);
  const [dynamicSubtitle, setSubtitle] = React.useState(data[0].subtitle);

  const renderSlides = (slide, index, length, callback) => {

    return <div className={"swiper-slide"}>
      {/*<video className={`slide_video js-open-youtube-${++index}`} playsInline muted loop pip="false" autoPlay>
        <source type="video/mp4" src={process.env.PUBLIC_URL + slide.video}/>
      </video>*/}
      <div className={"slide_video"}>
        <Image className={` js-open-youtube-${index + 1}`} src={slide.video}/>
        <Button className={`js-open-youtube-${index + 1}`} svgName={"play"} />
      </div>
      <div className={"slide_text"}>
        <img className={"icon"} src={process.env.PUBLIC_URL + slide.icon}/>
        <TitleBlock title={slide.title}/>
        <div className={"slide_subtitle"}>
          {slide.text}
        </div>
      </div>
    </div>;

  }
  return (
    <LanguageContext.Consumer>
      {context => {
        return (
          <div className={`pp-section safe-care ${dir}`}>
            <div className={"container"}>
              <PopupYoutube youtubeId={"9YzTMVCewVc"} btnOpen={"js-open-youtube-1"}/>
              <PopupYoutube youtubeId={"H2loHthb8-k"} btnOpen={"js-open-youtube-2"}/>
              <PopupYoutube youtubeId={"6K9G6AWWg5I"} btnOpen={"js-open-youtube-3"}/>
              <PopupYoutube youtubeId={"dH7UDdYSSeE"} btnOpen={"js-open-youtube-4"}/>
              {window.innerWidth > 1024 ? <div className={"content"}>
                  <div className={"content_top"}>
                    <div className={"content_top_icon"}>
                      <div onClick={() => setSlide(1)} className={"item"}>
                        <svg className={`${slide === 1 ? "active" : ""}`} width="48" height="76" viewBox="0 0 48 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.01938 7.5C3.01938 3.63401 6.15339 0.5 10.0194 0.5H12.0194C15.8854 0.5 19.0194 3.63401 19.0194 7.5V10.8193C19.0618 10.85 19.1024 10.8846 19.1407 10.9228L21.98 13.7621C22.3705 14.1527 22.3705 14.7858 21.98 15.1763C21.5895 15.5669 20.9563 15.5669 20.5658 15.1763L19.0194 13.6299V33.5C19.0194 36.2614 16.7808 38.5 14.0194 38.5H8.01938C5.25796 38.5 3.01938 36.2614 3.01938 33.5V13.8641L1.70711 15.1763C1.31658 15.5669 0.683417 15.5669 0.292893 15.1763C-0.0976311 14.7858 -0.0976311 14.1527 0.292893 13.7621L3.01938 11.0356V7.5ZM17.0199 13C17.0199 9.50003 14.6731 8.5 11.0194 8.5C7.36577 8.5 5.01949 9.5 5.01949 13C5.01949 13.2452 5.01704 13.4781 5.0147 13.6993V13.6993V13.6993L5.0147 13.6993C4.98376 16.6353 4.97465 17.5 11.0194 17.5C17.0646 17.5 17.0555 16.6351 17.0246 13.6986L17.0246 13.6984V13.6982V13.698C17.0223 13.4772 17.0199 13.2447 17.0199 13ZM11.0191 34.5C14.6673 34.5 17.0105 33.9444 17.0105 32C17.0105 31.864 17.013 31.7348 17.0153 31.6122V31.612L17.0153 31.6119C17.0461 29.9805 17.0552 29.5 11.0191 29.5C4.98349 29.5 4.99259 29.9804 5.02349 31.6115C5.02582 31.7344 5.02827 31.8638 5.02827 32C5.02827 33.9444 7.371 34.5 11.0191 34.5ZM16.0194 18.9142C16.0194 18.649 16.1247 18.3946 16.3123 18.2071L18.0194 16.5V29.5L16.5722 28.7764C16.2334 28.607 16.0194 28.2607 16.0194 27.882V18.9142ZM5.72649 18.2071C5.91402 18.3946 6.01938 18.649 6.01938 18.9142V27.882C6.01938 28.2607 5.80538 28.607 5.46659 28.7764L4.01938 29.5V16.5L5.72649 18.2071Z" fill="#127EB2"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M21.8565 37.4281H20.7562C20.7562 38.9865 19.487 40.2558 17.9285 40.2558L17.9278 41.3568C20.0944 41.3554 21.8558 39.594 21.8565 37.4281Z" fill="#127EB2"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M25.2344 37.4284L24.1341 37.4284C24.1341 39.0858 23.4892 40.6443 22.3168 41.8167C21.1452 42.9883 19.5867 43.6332 17.9278 43.6332L17.9285 44.7342C19.8794 44.7335 21.7151 43.974 23.0946 42.5945C24.4749 41.2142 25.2344 39.38 25.2344 37.4284Z" fill="#127EB2"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M28.6122 37.4278H27.5119C27.5119 39.9875 26.5149 42.3945 24.7047 44.2047C22.8945 46.0149 20.4882 47.0112 17.9271 47.0126L17.9278 48.1121C20.7824 48.1128 23.4659 47.0006 25.4832 44.9832C27.5013 42.9651 28.6136 40.2816 28.6122 37.4278Z" fill="#127EB2"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M28.0387 75.2507V61.8641L26.7264 63.1763C26.3359 63.5669 25.7027 63.5669 25.3122 63.1763C24.9217 62.7858 24.9217 62.1527 25.3122 61.7621L28.0387 59.0356V55.5C28.0387 51.634 31.1727 48.5 35.0387 48.5H37.0387C40.9047 48.5 44.0387 51.634 44.0387 55.5V58.8193C44.0811 58.85 44.1217 58.8846 44.16 58.9228L46.9993 61.7621C47.3898 62.1527 47.3898 62.7858 46.9993 63.1763C46.6088 63.5669 45.9756 63.5669 45.5851 63.1763L44.0387 61.6299V69.9246C43.7083 70.1191 43.3749 70.3091 43.0387 70.4944V64.5L41.3316 66.2071C41.144 66.3946 41.0387 66.649 41.0387 66.9142V71.525C37.9076 73.0296 34.5532 74.1434 31.0387 74.8033V66.9142C31.0387 66.649 30.9333 66.3946 30.7458 66.2071L29.0387 64.5V75.1271C28.7066 75.1723 28.3732 75.2135 28.0387 75.2507ZM36.0387 56.5C39.6924 56.5 42.0392 57.5 42.0392 61C42.0392 61.2447 42.0416 61.4771 42.0439 61.6978V61.698V61.6982V61.6984L42.0439 61.6986C42.0748 64.6351 42.0839 65.5 36.0387 65.5C29.9939 65.5 30.003 64.6353 30.034 61.6993L30.034 61.6993V61.6993V61.6993V61.6992C30.0363 61.4781 30.0388 61.2452 30.0388 61C30.0388 57.5 32.3851 56.5 36.0387 56.5Z" fill="#127EB2"/>
                        </svg>
                        <div>{context.getTranslate(data)[0].title}</div>
                      </div>
                      <div onClick={() => setSlide(2)} className={"item"}>
                        <svg className={`${slide === 2 ? "active" : ""}`} width="82" height="79" viewBox="0 0 82 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M42.9998 6.44406L42.9998 16.5001L38.934 16.5001L38.934 6.44406L36.9998 6.44406L40.9998 -1.74846e-07L44.9998 6.44406L42.9998 6.44406Z" fill="#127EB2"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M33.0191 28.5C33.0191 24.634 36.1531 21.5 40.0191 21.5L42.0191 21.5C45.8851 21.5 49.0191 24.634 49.0191 28.5L49.0191 31.8193C49.0616 31.8501 49.1022 31.8846 49.1404 31.9228L51.9797 34.7621C52.3703 35.1527 52.3703 35.7858 51.9797 36.1763C51.5892 36.5669 50.9561 36.5669 50.5655 36.1763L49.0191 34.6299L49.0191 54.5C49.0191 57.2614 46.7806 59.5 44.0191 59.5L38.0191 59.5C35.2577 59.5 33.0191 57.2614 33.0191 54.5L33.0191 34.8641L31.7069 36.1763C31.3163 36.5669 30.6832 36.5669 30.2926 36.1763C29.9021 35.7858 29.9021 35.1527 30.2926 34.7621L33.0191 32.0356L33.0191 28.5ZM47.0196 34C47.0196 30.5 44.6728 29.5 41.0192 29.5C37.3655 29.5 35.0192 30.5 35.0192 34C35.0192 34.2452 35.0168 34.4781 35.0145 34.6993L35.0145 34.6993L35.0145 34.6993L35.0145 34.6993C34.9835 37.6353 34.9744 38.5 41.0192 38.5C47.0644 38.5 47.0553 37.6351 47.0244 34.6986L47.0244 34.6984L47.0244 34.6982L47.0244 34.698C47.0221 34.4772 47.0196 34.2447 47.0196 34ZM41.0189 55.5C44.667 55.5 47.0103 54.9444 47.0103 53C47.0103 52.864 47.0127 52.7348 47.015 52.6122L47.015 52.612L47.015 52.6119C47.0459 50.9805 47.0549 50.5 41.0189 50.5C34.9832 50.5 34.9923 50.9804 35.0232 52.6115L35.0232 52.6115C35.0256 52.7344 35.028 52.8638 35.028 53C35.028 54.9444 37.3707 55.5 41.0189 55.5ZM46.0191 39.9142C46.0191 39.649 46.1245 39.3946 46.312 39.2071L48.0191 37.5L48.0191 50.5L46.5719 49.7764C46.2331 49.607 46.0191 49.2607 46.0191 48.882L46.0191 39.9142ZM35.7262 39.2071C35.9138 39.3946 36.0191 39.649 36.0191 39.9142L36.0191 48.882C36.0191 49.2607 35.8051 49.607 35.4663 49.7764L34.0191 50.5L34.0191 37.5L35.7262 39.2071Z" fill="#127EB2"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.01913 44.5C3.01914 40.634 6.15314 37.5 10.0191 37.5L12.0191 37.5C15.8851 37.5 19.0191 40.634 19.0191 44.5L19.0191 47.8193C19.0616 47.8501 19.1022 47.8846 19.1404 47.9228L21.9797 50.7621C22.3703 51.1527 22.3703 51.7858 21.9797 52.1763C21.5892 52.5669 20.9561 52.5669 20.5655 52.1763L19.0191 50.6299L19.0191 70.5C19.0191 73.2614 16.7806 75.5 14.0191 75.5L8.01913 75.5C5.25771 75.5 3.01913 73.2614 3.01913 70.5L3.01913 50.8641L1.70686 52.1763C1.31634 52.5669 0.683171 52.5669 0.292646 52.1763C-0.0978777 51.7858 -0.0978776 51.1527 0.292647 50.7621L3.01913 48.0356L3.01913 44.5ZM17.0196 50C17.0196 46.5 14.6728 45.5 11.0192 45.5C7.36552 45.5 5.01924 46.5 5.01924 50C5.01924 50.2452 5.01679 50.4781 5.01446 50.6993L5.01446 50.6993L5.01446 50.6993L5.01446 50.6993C4.98351 53.6353 4.9744 54.5 11.0192 54.5C17.0644 54.5 17.0553 53.6351 17.0244 50.6986L17.0244 50.6984L17.0244 50.6982L17.0244 50.698C17.0221 50.4772 17.0196 50.2447 17.0196 50ZM11.0189 71.5C14.667 71.5 17.0103 70.9444 17.0103 69C17.0103 68.864 17.0127 68.7348 17.015 68.6122L17.015 68.612L17.015 68.6119C17.0459 66.9805 17.0549 66.5 11.0189 66.5C4.98324 66.5 4.99234 66.9804 5.02324 68.6115L5.02324 68.6115C5.02557 68.7344 5.02802 68.8638 5.02802 69C5.02802 70.9444 7.37075 71.5 11.0189 71.5ZM16.0191 55.9142C16.0191 55.649 16.1245 55.3946 16.312 55.2071L18.0191 53.5L18.0191 66.5L16.5719 65.7764C16.2331 65.607 16.0191 65.2607 16.0191 64.882L16.0191 55.9142ZM5.72624 55.2071C5.91378 55.3946 6.01913 55.649 6.01913 55.9142L6.01913 64.882C6.01913 65.2607 5.80513 65.607 5.46634 65.7764L4.01913 66.5L4.01913 53.5L5.72624 55.2071Z" fill="#127EB2"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M62.7464 44.5C62.7464 40.634 65.8804 37.5 69.7464 37.5L71.7464 37.5C75.6124 37.5 78.7464 40.634 78.7464 44.5L78.7464 47.8193C78.7889 47.8501 78.8295 47.8846 78.8677 47.9228L81.707 50.7621C82.0976 51.1527 82.0976 51.7858 81.707 52.1763C81.3165 52.5669 80.6834 52.5669 80.2928 52.1763L78.7464 50.6299L78.7464 70.5C78.7464 73.2614 76.5078 75.5 73.7464 75.5L67.7464 75.5C64.985 75.5 62.7464 73.2614 62.7464 70.5L62.7464 50.8641L61.4342 52.1763C61.0436 52.5669 60.4105 52.5669 60.0199 52.1763C59.6294 51.7858 59.6294 51.1527 60.0199 50.7621L62.7464 48.0356L62.7464 44.5ZM76.7469 50C76.7469 46.5 74.4001 45.5 70.7465 45.5C67.0928 45.5 64.7465 46.5 64.7465 50C64.7465 50.2452 64.7441 50.4781 64.7418 50.6993L64.7418 50.6993L64.7418 50.6993L64.7418 50.6993C64.7108 53.6353 64.7017 54.5 70.7465 54.5C76.7917 54.5 76.7826 53.6351 76.7517 50.6986L76.7517 50.6984L76.7517 50.6982L76.7517 50.698C76.7494 50.4772 76.7469 50.2447 76.7469 50ZM70.7462 71.5C74.3943 71.5 76.7376 70.9444 76.7376 69C76.7376 68.864 76.74 68.7348 76.7423 68.6122L76.7423 68.612L76.7423 68.6119C76.7732 66.9805 76.7822 66.5 70.7462 66.5C64.7105 66.5 64.7196 66.9804 64.7505 68.6115L64.7505 68.6115C64.7529 68.7344 64.7553 68.8638 64.7553 69C64.7553 70.9444 67.098 71.5 70.7462 71.5ZM75.7464 55.9142C75.7464 55.649 75.8518 55.3946 76.0393 55.2071L77.7464 53.5L77.7464 66.5L76.2992 65.7764C75.9604 65.607 75.7464 65.2607 75.7464 64.882L75.7464 55.9142ZM65.4535 55.2071C65.6411 55.3946 65.7464 55.649 65.7464 55.9142L65.7464 64.882C65.7464 65.2607 65.5324 65.607 65.1936 65.7764L63.7464 66.5L63.7464 53.5L65.4535 55.2071Z" fill="#127EB2"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M51.7778 16.1674L50.9998 16.9454C52.1018 18.0474 52.1018 19.8424 50.9998 20.9444L51.7778 21.7234C53.3088 20.1904 53.3088 17.6994 51.7778 16.1674Z" fill="#127EB2"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M54.1665 13.7791L53.3884 14.5571C54.5604 15.7291 55.2065 17.2871 55.2065 18.9451C55.2065 20.6021 54.5605 22.1601 53.3875 23.3331L54.1665 24.1111C55.5455 22.7311 56.3065 20.8961 56.3065 18.9451C56.3065 16.9931 55.5465 15.1591 54.1665 13.7791Z" fill="#127EB2"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M56.5546 11.39L55.7766 12.168C57.5866 13.978 58.5836 16.385 58.5836 18.945C58.5836 21.505 57.5866 23.911 55.7766 25.723L56.5546 26.5C58.5736 24.482 59.6846 21.798 59.6846 18.945C59.6846 16.091 58.5736 13.407 56.5546 11.39Z" fill="#127EB2"/>
                          <path d="M56.9998 78.5L54.9998 78.5L54.9998 34.5L56.9998 34.5L56.9998 78.5Z" fill="#127EB2"/>
                          <path d="M52 78.5L52 76.5L60 76.5L60 78.5L52 78.5Z" fill="#127EB2"/>
                          <path d="M22 78.5L22 76.5L30 76.5L30 78.5L22 78.5Z" fill="#127EB2"/>
                          <path d="M26.9998 78.5L24.9998 78.5L24.9998 34.5L26.9998 34.5L26.9998 78.5Z" fill="#127EB2"/>
                        </svg>

                        <div>{context.getTranslate(data)[1].title}</div>
                      </div>
                      <div onClick={() => setSlide(3)} className={"item"}>
                        <svg className={`${slide === 3 ? "active" : ""}`} width="86" height="44" viewBox="0 0 86 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M19.5771 20.3185C18.7032 21.135 16.5493 22.7464 13.7846 22.0056C10.9609 21.249 9.7002 19.0953 9.41691 17.4719L8.82946 17.3145C8.7303 17.2808 7.76734 17.0499 7.14529 16.0795C6.59468 15.2182 6.49688 14.097 6.85806 12.7491L8.13886 7.98593C8.17024 7.79591 8.49713 6.11047 9.93135 5.24994C10.5105 4.89977 11.5038 4.56348 12.9366 4.94741L16.9478 6.0222L22.5587 3.82969L38.982 8.23028L42.4417 12.3108C43.8642 12.8961 50.0112 15.5111 51.9149 17.7942C51.984 17.8841 53.6398 20.0551 53.0687 22.1862L51.8957 26.5414C51.8795 26.6413 51.7288 27.5402 50.9149 28.0431C50.5366 28.2772 49.8828 28.5017 48.9225 28.2444L46.9652 27.72C46.1455 28.5082 44.1621 30.1452 41.7085 29.4878C38.7797 28.703 38.0351 27.6684 37.157 25.029L19.5771 20.3185Z" fill="#127EB2"/>
                          <path d="M0 20.4319L0.517637 18.5L85.8926 41.3762L85.375 43.308L0 20.4319Z" fill="#127EB2"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M69 4.5L54 30.4808H84L69 4.5ZM69 8.5L57.4641 28.4808H80.5359L69 8.5Z" fill="#127EB2"/>
                          <path d="M68.2104 12.3945H69.7894V23.4472H68.2104V12.3945Z" fill="#127EB2"/>
                          <path d="M69.7894 25.8158C69.7894 26.2519 69.4359 26.6053 68.9999 26.6053C68.5639 26.6053 68.2104 26.2519 68.2104 25.8158C68.2104 25.3798 68.5639 25.0264 68.9999 25.0264C69.4359 25.0264 69.7894 25.3798 69.7894 25.8158Z" fill="#127EB2"/>
                        </svg>

                        <div>{context.getTranslate(data)[2].title}</div>
                      </div>
                      <div onClick={() => setSlide(4)} className={"item"}>

                        <svg className={`${slide === 4 ? "active" : ""}`} width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M26.1947 22.675C26.1947 18.8091 29.3287 15.675 33.1947 15.6751L35.1947 15.6751C39.0607 15.6751 42.1947 18.8091 42.1947 22.6751L42.1947 25.9943C42.2371 26.0251 42.2777 26.0596 42.316 26.0979L45.1553 28.9372C45.5458 29.3277 45.5458 29.9609 45.1553 30.3514C44.7648 30.7419 44.1316 30.7419 43.7411 30.3514L42.1947 28.805L42.1947 48.6751C42.1947 51.4365 39.9561 53.6751 37.1947 53.6751L31.1947 53.6751C28.4332 53.675 26.1947 51.4365 26.1947 48.675L26.1947 29.0391L24.8824 30.3514C24.4919 30.7419 23.8587 30.7419 23.4682 30.3514C23.0777 29.9609 23.0777 29.3277 23.4682 28.9372L26.1947 26.2107L26.1947 22.675ZM40.1952 28.1751C40.1952 24.6751 37.8484 23.6751 34.1947 23.6751C30.5411 23.6751 28.1948 24.675 28.1948 28.1751C28.1948 28.4203 28.1923 28.6532 28.19 28.8743L28.19 28.8743L28.19 28.8744L28.19 28.8744C28.1591 31.8103 28.1499 32.6751 34.1947 32.6751C40.2399 32.6751 40.2308 31.8101 40.1999 28.8737L40.1999 28.8735L40.1999 28.8733L40.1999 28.8731C40.1976 28.6523 40.1952 28.4198 40.1952 28.1751ZM34.1944 49.6751C37.8426 49.6751 40.1858 49.1195 40.1858 47.175C40.1858 47.0391 40.1882 46.9099 40.1906 46.7872L40.1906 46.7871L40.1906 46.787C40.2214 45.1556 40.2305 44.6751 34.1944 44.6751C28.1588 44.675 28.1679 45.1555 28.1988 46.7865C28.2011 46.9094 28.2036 47.0388 28.2036 47.175C28.2036 49.1195 30.5463 49.6751 34.1944 49.6751ZM39.1947 34.0893C39.1947 33.824 39.3 33.5697 39.4876 33.3822L41.1947 31.6751L41.1947 44.6751L39.7475 43.9514C39.4087 43.7821 39.1947 43.4358 39.1947 43.057L39.1947 34.0893ZM28.9018 33.3822C29.0893 33.5697 29.1947 33.824 29.1947 34.0893L29.1947 43.057C29.1947 43.4358 28.9807 43.7821 28.6419 43.9514L27.1947 44.675L27.1947 31.675L28.9018 33.3822Z" fill="#127EB2"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4162 8.23037C13.2043 8.0184 13.2256 7.66789 13.4638 7.48594C25.6598 -1.82865 42.6906 -1.82865 54.8866 7.48594C55.1248 7.66789 55.1461 8.0184 54.9342 8.23038C54.755 8.40958 54.4695 8.42567 54.268 8.27194C42.4339 -0.757313 25.9165 -0.757313 14.0824 8.27194C13.8809 8.42566 13.5954 8.40957 13.4162 8.23037ZM7.73026 13.9164C7.51828 13.7044 7.16777 13.7257 6.98582 13.964C-2.32865 26.1599 -2.32861 43.1907 6.98594 55.3866C7.16789 55.6248 7.5184 55.6461 7.73037 55.4342C7.90957 55.255 7.92567 54.9695 7.77194 54.768C-1.25728 42.934 -1.25731 26.4166 7.77182 14.5825C7.92554 14.3811 7.90945 14.0956 7.73026 13.9164ZM54.934 61.1201C54.7548 60.9409 54.4693 60.9249 54.2678 61.0786C42.4338 70.1077 25.9166 70.1077 14.0826 61.0786C13.8811 60.9249 13.5956 60.9409 13.4164 61.1201C13.2044 61.3321 13.2257 61.6826 13.464 61.8646C25.6599 71.179 42.6905 71.179 54.8864 61.8646C55.1247 61.6826 55.146 61.3321 54.934 61.1201ZM60.62 55.4342C60.832 55.6461 61.1825 55.6248 61.3644 55.3866C70.679 43.1907 70.679 26.1599 61.3646 13.964C61.1826 13.7257 60.8321 13.7044 60.6201 13.9164C60.4409 14.0956 60.4249 14.3811 60.5786 14.5826C69.6077 26.4166 69.6077 42.934 60.5785 54.768C60.4247 54.9695 60.4408 55.255 60.62 55.4342Z" fill="#127EB2"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5875 16.0599C19.3753 15.8477 19.3963 15.497 19.636 15.3166C28.2215 8.85154 40.1288 8.85151 48.7144 15.3165C48.9541 15.4969 48.975 15.8476 48.7629 16.0598C48.5838 16.2389 48.2988 16.2552 48.0963 16.103C39.8725 9.92267 28.4778 9.9227 20.2541 16.1031C20.0516 16.2553 19.7666 16.239 19.5875 16.0599ZM15.5599 20.0874C15.3477 19.8753 14.997 19.8963 14.8166 20.1359C8.35154 28.7215 8.35151 40.6288 14.8165 49.2144C14.9969 49.4541 15.3476 49.475 15.5598 49.2629C15.7389 49.0838 15.7552 48.7988 15.603 48.5963C9.42267 40.3725 9.4227 28.9778 15.6031 20.7541C15.7553 20.5516 15.739 20.2666 15.5599 20.0874ZM48.763 53.2906C48.5839 53.1115 48.2989 53.0952 48.0964 53.2474C39.8726 59.4278 28.4777 59.4278 20.254 53.2473C20.0515 53.0951 19.7664 53.1114 19.5873 53.2905C19.3752 53.5027 19.3962 53.8533 19.6358 54.0338C28.2214 60.499 40.1289 60.499 48.7145 54.0339C48.9542 53.8534 48.9752 53.5028 48.763 53.2906ZM52.7906 49.263C53.0028 49.4752 53.3534 49.4542 53.5339 49.2145C59.999 40.6289 59.999 28.7214 53.5338 20.1358C53.3533 19.8962 53.0027 19.8752 52.7905 20.0873C52.6114 20.2664 52.5951 20.5515 52.7473 20.754C58.9278 28.9777 58.9278 40.3726 52.7474 48.5964C52.5952 48.7989 52.6115 49.0839 52.7906 49.263Z" fill="#127EB2"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8517 12.5891C16.6396 12.377 16.6608 12.0264 16.8997 11.8452C27.0859 4.11703 41.2645 4.11703 51.4508 11.8452C51.6897 12.0264 51.7108 12.377 51.4988 12.5891C51.3196 12.7683 51.0344 12.7844 50.8324 12.6314C41.008 5.18829 27.3424 5.18829 17.5181 12.6314C17.3161 12.7844 17.0308 12.7683 16.8517 12.5891ZM12.0889 17.3519C11.8769 17.1398 11.5263 17.161 11.345 17.3999C3.61703 27.5861 3.61708 41.7646 11.3452 51.9508C11.5264 52.1897 11.877 52.2108 12.0891 51.9988C12.2683 51.8196 12.2844 51.5344 12.1314 51.3324C4.68834 41.5081 4.68829 27.8426 12.1313 18.0183C12.2843 17.8163 12.2681 17.531 12.0889 17.3519ZM51.4986 56.7615C51.3194 56.5824 51.0342 56.5662 50.8322 56.7192C41.0079 64.1621 27.3426 64.1621 17.5183 56.7192C17.3163 56.5662 17.031 56.5824 16.8519 56.7615C16.6398 56.9736 16.661 57.3242 16.8999 57.5054C27.0861 65.2334 41.2644 65.2334 51.4506 57.5054C51.6895 57.3242 51.7106 56.9736 51.4986 56.7615ZM56.2614 51.9988C56.4734 52.2108 56.824 52.1897 57.0053 51.9508C64.7334 41.7646 64.7334 27.5861 57.0054 17.3999C56.8242 17.161 56.4736 17.1398 56.2615 17.3519C56.0824 17.531 56.0662 17.8163 56.2192 18.0183C63.6622 27.8426 63.6621 41.5081 56.219 51.3324C56.066 51.5344 56.0822 51.8196 56.2614 51.9988Z" fill="#127EB2"/>
                        </svg>
                        <div>{context.getTranslate(data)[3].title}</div>
                      </div>
                    </div>
                    <div style={dir === "ltr"
                        ? {left: `${(slide - 1) * 100 / context.getTranslate(data).length}%`, width: `${100 / context.getTranslate(data).length}%`}
                        : {left: `-${(slide - 1) * 100 / context.getTranslate(data).length}%`, width: `${100 / context.getTranslate(data).length}%`}} className={`bar`}></div>
                  </div>
                  {
                    context.getTranslate(data).map((item, index) => <div
                      className={`content_bottom ${slide === index + 1 ? "active" : "hide"}`}>
                      {/*<video className={`video js-open-youtube-${++index}`} playsInline muted loop pip="false" autoPlay>
                        <source type="video/mp4" src={process.env.PUBLIC_URL + item.video}/>
                      </video>*/}
                      <div className={"video"}>
                        <Button className={`js-open-youtube-${index + 1}`} svgName={"play"} />
                        <Image className={`js-open-youtube-${index + 1}`} src={item.video}/>
                      </div>

                      <div className={"text"}>
                        <TitleBlock title={item.title}/>
                        <div className={"subtitle"}>
                          {item.text}
                        </div>
                      </div>
                    </div>)
                  }
                </div>
                : <Slider slides={context.getTranslate(data)}
                          className={`visible ${"" || ""}`}
                          forceOnChange={true}
                          dir={dir}
                          useControls={true}
                          usePagination={true}
                          onChange={(e) => {
                            setTitle("data[e].title");
                            setSubtitle("data[e].subtitle");
                          }}
                          renderSlide={renderSlides}
                          config={{slidesPerView: 1, spaceBetween: 40}}/>}
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>)
};
