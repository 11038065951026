import React from 'react';
import PropTypes from 'prop-types';
import Image from "../../components/UI/Images/Image";
import ToggleLang from "../../components/ToggleLang/ToggleLang";
import "./Header.scss";
import {LanguageContext} from "../../contexts/LanguageContext";

const Header = ({ dir }) => {

    return (
        <LanguageContext.Consumer>

            {context => {
                return (
                    <header dir={dir}>
                        <div className={"container"}>
                            <div className={"menu"}>
                                <Image src={"svg/logo.svg"}
                                       useLazy={false}
                                       alt={"Hyundai"}
                                       className={"logo"} />
                            </div>
                            <Image src={"/svg/top.svg"} className={"top-button desktop"} onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth"
                                })
                            }}/>
                            <ToggleLang className={"desktop"}/>
                        </div>
                    </header>
                );
            }}
        </LanguageContext.Consumer>
    );

};

Header.propTypes = {};

export default Header;
