import React from 'react';
import PropTypes from 'prop-types';
import "./Play.scss"
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";

export const Play = ({ title, subtitle, bubble, youtubeId }) => {

  return (
    <div className={"pp-section play"}>
      <div className={"container"}>

        <TitleBlock title={title} subtitle={subtitle}/>

        <div className={"wrapper_youtube"}>
          <iframe className={"youtube"} src={`https://www.youtube.com/embed/${youtubeId}`} frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
        </div>
      </div>
    </div>
  )

};

Play.propTypes = {
  title: PropTypes.string.isRequired,
  bubble: PropTypes.string.isRequired,
};
